<template>
  <div v-if="isLatestVersion && isProductWritable" class="flex gap-24">
    <div class="flex-1 max-w-[63.2rem]">
      <st-box>
        <div v-if="isEditMode && term" class="flex gap-8 items-center">
          <p class="text-xl font-bold leading-lg text-on-surface-elevation-2">
            {{
              $t(
                `${termTypesOptions.find((t: FormOption) => t.value === term?.subCategory)?.label}`
              )
            }}
          </p>
          <span class="text-xs font-medium leading-xs text-on-surface-elevation-3">
            {{ term?.version.toUpperCase() }}
          </span>
        </div>
        <template v-if="!isEditMode">
          <st-form-title
            :formTitle="$t('studio.prj_prod_mngmt.prod_home.create_term.select_type')"
            required
            class="mt-20"
          />
          <p class="text-xs leading-xs text-placeholder font-regular mt-4">
            {{ $t('studio.prj_prod_mngmt.prod_home.create_term.select_type_guide') }}
          </p>
          <div v-if="!isEditMode" class="relative mt-8">
            <dropdown
              name="subCategory"
              :options="termTypesOptions"
              :dropdownProps="{
                size: 'lg',
                variant: 'line',
                distance: 4,
                offset: [0, 0],
                placement: 'bottom',
                class: 'w-full'
              }"
              :rules="{
                required: {
                  value: true,
                  message: $t(
                    'studio.prj_prod_mngmt.prod_home.create_term.select_type_required_msg'
                  )
                }
              }"
              :placeholder="$t('studio.prj_prod_mngmt.prod_home.create_term.select_type_dropbox')"
              closeOnClick
              @update:modelValue="handleTermTypeChange"
            />
          </div>
        </template>

        <template v-if="values.subCategory">
          <st-form-title
            :formTitle="$t('studio.prj_prod_mngmt.prod_home.create_term.term_name')"
            class="mt-32"
            required
          />
          <p class="text-xs leading-xs text-placeholder font-regular mt-4">
            {{ $t('studio.prj_prod_mngmt.prod_home.create_term.term_name_guide') }}
          </p>
          <div class="mt-8">
            <input-text
              maxLength="50"
              :placeholder="
                $t('studio.prj_prod_mngmt.prod_home.create_term.term_name_place_holder')
              "
              :rules="{
                required: {
                  value: true,
                  message: $t('studio.prj_prod_mngmt.prod_home.create_term.term_name_req_msg')
                },
                max_length: {
                  length: 50
                }
              }"
              name="termName"
            />
          </div>
          <st-form-title
            :formTitle="$t('studio.prj_prod_mngmt.prod_home.create_term.term_content_title')"
            languageUse
            alignLeftLanguageUse
            required
            class="mt-32"
            :slotClass="'ml-auto'"
            :referenceLanguage="$t(`${referenceLanguage}`)"
            @onLanguageClick="onSettingMultiLanguage"
          >
            <div class="shrink-0 flex items-center gap-4">
              <s-button
                size="xs"
                variant="outline"
                icon="ic-v2-media-av-fullscreen-fill"
                :isDisabled="isCodeView"
                @click="handleFullScreen"
              >
                {{ $t('studio.prj_prod_mngmt.prod_home.create_term.btn_input_fullscr') }}
              </s-button>
              <s-tooltip
                arrow
                :content="$t('studio.prj_prod.this_prod.home_early_access_tooltip_hover_msg')"
                :duration="0"
                :offset="[8, 4]"
                useFlip
                flipOnUpdate
                placement="bottom-end"
                trigger="mouseenter focus"
                :theme="'studio-tooltip'"
                :zIndex="2500"
              >
                <template #target>
                  <s-icon
                    size="xl"
                    icon="ic-v2-state-info-circle-line"
                    class="h-16 text-on-surface-elevation-4"
                  />
                </template>
              </s-tooltip>
            </div>
          </st-form-title>
          <p class="text-xs leading-xs text-placeholder font-regular mt-4">
            {{ $t('studio.prj_prod_mngmt.prod_home.create_term.term_content_guide') }}
          </p>
          <div class="mt-16">
            <dropdown
              v-model="selectedVersion"
              :options="allReleasedVersions"
              :placeholder="$t('studio.prj_prod_mngmt.prod_home.create_term.term_version_select')"
              @update:modelValue="onVersionChange"
            >
              <template #dropdown-button>
                {{ $t('studio.prj_prod_mngmt.prod_home.create_term.term_version_select') }}
              </template>
            </dropdown>
          </div>
          <!-- 에디터 -->
          <text-editor
            v-if="rerenderEditor"
            v-model="termContent"
            name="termContent"
            class="mt-[2rem] h-[52rem]"
            :config="editorConfig"
            :charCounterMax="WORD_COUNT_LIMIT"
            :rules="{
              required: {
                value: true,
                message: $t('studio.prj_prod_mngmt.prod_home.create_term.eula_content_req_msg')
              },
              max_char_count: {
                length: WORD_COUNT_LIMIT,
                charCount: wordCount,
                message: $t('studio.common.def_key.exceed', {
                  length: WORD_COUNT_LIMIT
                })
              }
            }"
            :toolbarButtons="TOOLBAR_BUTTON_TERMS"
            :projectId="projectId"
            :showError="false"
            @update:modelValue="handleModelUpdate"
            @onClickBtnHtmlCodeBlock="isCodeView = $event"
            @wordCount="wordCount = $event"
          />
          <st-error-message v-if="isTermContentError" name="termContent" />
          <template v-if="isEditMode">
            <st-form-title
              :formTitle="$t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_date')"
              :class="showError ? 'mt-24' : 'mt-8'"
              required
            />
            <p class="text-xs leading-xs text-placeholder font-regular mt-4">
              {{ $t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_date_guide') }}
            </p>
            <div class="flex flex-col gap-8 mt-12">
              <radio-group
                name="effectiveFrom"
                :options="effectiveDateOptions"
                :rules="{
                  required: true
                }"
                :optionProps="{
                  size: 'sm',
                  align: 'middle'
                }"
                class="flex flex-col gap-8 mt-12"
              />
              <datetime-picker
                v-if="effectiveFrom === EFFECTIVE_FROM.RESERVATION"
                name="effectiveDate"
                :rules="{
                  required: {
                    value: true,
                    message: $t(
                      'studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_date_required_msg'
                    )
                  }
                }"
              />
            </div>
          </template>
        </template>
      </st-box>

      <div class="mt-40 flex justify-center gap-16">
        <s-button variant="primary" size="lg" class="!min-w-160" @click="checkTermContent">
          {{
            isEditMode
              ? $t('studio.common.live_apply_btn')
              : $t('studio.common.popup_case_save_btn')
          }}
        </s-button>
      </div>
    </div>

    <right-wing :key="termComponent(values.subCategory)">
      <component
        :is="termComponents[termComponent(values.subCategory) as keyof typeof termComponents]"
      />
    </right-wing>
  </div>

  <st-box v-else class="mt-40 space-y-20">
    <div class="flex items-center gap-8">
      <p class="text-xl font-bold leading-lg text-on-surface-elevation-2">
        {{
          $t(`${termTypesOptions.find((t: FormOption) => t.value === term?.subCategory)?.label}`)
        }}
      </p>
      <span class="text-xs font-medium leading-xs text-on-surface-elevation-3">{{
        term?.version.toUpperCase()
      }}</span>
    </div>
    <dl class="flex flex-col gap-4">
      <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
        {{ $t('studio.prj_prod_mngmt.prod_home.create_term.term_name') }}
      </dt>
      <dd class="text-xl font-bold leading-xl text-on-surface-elevation-2">{{ term?.name }}</dd>
    </dl>
    <dl class="flex flex-col gap-8">
      <dt class="flex items-center gap-4">
        <p class="text-md font-medium leading-sm text-on-surface-elevation-4">
          {{ $t('studio.ai_translation.title9_terms') }}
        </p>
        <div class="shrink-0 flex items-center gap-8 ml-auto">
          <span
            class="text-2xs leading-xs rounded-full px-[.6rem] h-[2.2rem] flex items-center text-gray500 bg-gray60"
          >
            {{ $t(`${referenceLanguage}`) }}
          </span>
          <s-button
            variant="outline"
            icon="ic-v2-communication-translate-line"
            size="sm"
            @click="onSettingMultiLanguage"
          >
            {{ $t('studio.ai_translation.title1_settings') }}
          </s-button>
        </div>
      </dt>
      <text-editor
        v-model="termContent"
        name="termContent"
        class="mt-[2rem] h-[52rem]"
        :config="editorConfig"
        :wordCountLimit="WORD_COUNT_LIMIT"
        :charCounterMax="WORD_COUNT_LIMIT"
        :toolbarButtons="TOOLBAR_BUTTON_TERMS"
        :showError="showError"
        :projectId="projectId"
        readonly
        @update:modelValue="showError = false"
        @onClickBtnHtmlCodeBlock="isCodeView = $event"
        @wordCount="wordCount = $event"
      />
    </dl>
    <dl class="flex flex-col gap-4">
      <dt class="text-md font-medium leading-sm text-on-surface-elevation-4">
        {{ $t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.view_mode_term_apply_date') }}
      </dt>
      <dd class="flex items-center gap-4">
        <p class="text-xl font-bold leading-xl text-on-surface-elevation-2">
          {{ formatDateTime(term?.enforcedAt, DATE_TIME_FORMAT_WITH_DOT) }}
        </p>
      </dd>
    </dl>
  </st-box>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue, useForm, useSetFieldError } from 'vee-validate';
import { computed, defineAsyncComponent, nextTick, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import { fetchBusinessInfosApi } from '@/apis/business-bank-info.api';
import {
  checkTermTypeRegisterApi,
  createTermApi,
  fetchTermByVersionIdApi,
  fetchTermTypeVersionListApi,
  updateTermByVersionApi
} from '@/apis/terms.api';
import RightWing from '@/components/app/right-wing/index.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import FullScreen from '@/components/early-access/fullscreen.vue';
import MultiLanguageDialog from '@/components/product/dialog/multilanguage-setting-dialog.vue';
import ReleaseDialog from '@/components/terms/release-dialog.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import StErrorMessage from '@/components/validation/st-error-message.vue';
import TextEditor from '@/components/validation/text-editor.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showDialog } from '@/composables/useDialog';
import { MODE } from '@/constants/common.const';
import { DATE_TIME_FORMAT_WITH_DOT } from '@/constants/date-time.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { TOOLBAR_BUTTON_TERMS } from '@/constants/froala.const';
import { EFFECTIVE_FROM, MULTILANGUAGE_DIALOG_NAME, TERM_TYPES } from '@/constants/terms.const';
import { PRODUCT_TERMS_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { LanguageCode } from '@/enums/language-code.enum';
import { TermEnforceTimeType, TermsConfirmType } from '@/enums/terms.enum';
import { RuleNames } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse, Language } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { TermsAndConditionRequest } from '@/types/terms/terms-request.type';
import type {
  TermDetailResponse,
  TermLanguageContent,
  TermTypeVersionResponse,
  TranslatedContent
} from '@/types/terms/terms-response.type';
import { redirectTo, showConfirmLeaveDialog } from '@/utils/common.util';
import { formatDateTime } from '@/utils/date.util';
import { getTermLabel } from '@/utils/term.util';
import { delayTime } from '@/utils/time.util';
import { generateRandomNumber } from '@/utils/uuid.util';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  mode: string;
}>();

const { checkProductPermission } = useApp();

interface TermForm {
  termName: string;
  lang: string;
  termContent: string;
  effectiveFrom?: string;
  effectiveDate?: Date;
  subCategory?: string;
  version?: string;
}

const WORD_COUNT_LIMIT = 14000;

const productStore = useProductStore();

const { product, isProductWritable } = storeToRefs(productStore);

defineEmits<{
  close: [v: void];
}>();

const route = useRoute();

const userStore = useUserStore();
const appStore = useAppStore();

const { fetchLanguages, startLoading, endLoading } = appStore;

const { languages } = storeToRefs(appStore);
const { selectedGroupInfo, selectedGroupId } = storeToRefs(userStore);

const { t } = useI18n();

const isEditMode = computed<boolean>(() => props.mode === MODE.EDIT);
const isCodeView = ref<boolean>(false);
const isCheckLeave = ref<boolean>(true);

const isLatestVersion = ref<boolean>(true);

const companyName = ref('');

const editorConfig = ref({
  attribution: false,
  charCounterCount: true,
  wordCounterCount: false,
  placeholderText: t('studio.prj_prod_mngmt.prod_home.create_term.term_content_place_holder'),
  height: '52rem'
});

const { setValues, values, validate, meta } = useForm<TermForm>({
  initialValues: {
    termName: '',
    lang: '',
    termContent: '',
    effectiveFrom: EFFECTIVE_FROM.IMMEDIATE,
    effectiveDate: undefined,
    subCategory: '',
    version: undefined
  },
  keepValuesOnUnmount: true
});

const effectiveFrom = useFieldValue<string>('effectiveFrom');
const isTermContentError = useFieldError('termContent');
const setContentError = useSetFieldError('termContent');

const termContent = ref<string>('');
const selectedVersion = ref<string>();
const wordCount = ref<number>(0);

const showError = ref<boolean>(false);
const rerenderEditor = ref<boolean>(true);

const isContentUpdated = ref<boolean>(false);

const allReleasedVersions = ref<FormOption[]>([]);
const term = ref<TermDetailResponse>();
const allVersions = ref<TermTypeVersionResponse[]>([]);

const termComponents = {
  [TERM_TYPES.LICENSE_AGREEMENT]: defineAsyncComponent(
    () => import('@/components/project-product/detail/term/license-agreement.vue')
  ),
  [TERM_TYPES.TERMS_OF_SERVICE]: defineAsyncComponent(
    () => import('@/components/project-product/detail/term/terms-of-service.vue')
  ),
  [TERM_TYPES.CHECKOUT_NOTE]: defineAsyncComponent(
    () => import('@/components/project-product/detail/term/checkout-note.vue')
  ),
  [TERM_TYPES.THIRD_PARTY]: defineAsyncComponent(
    () => import('@/components/project-product/detail/term/third-party.vue')
  ),
  [TERM_TYPES.PROTECTION_AUTOMATIC]: defineAsyncComponent(
    () => import('@/components/project-product/detail/term/protection-automatic.vue')
  ),
  [TERM_TYPES.PARENTAL_CONSENT]: defineAsyncComponent(
    () => import('@/components/project-product/detail/term/parental-consent.vue')
  ),
  [TERM_TYPES.PERSONAL_CONSENT]: defineAsyncComponent(
    () => import('@/components/project-product/detail/term/personal-consent.vue')
  ),
  '': defineAsyncComponent(
    () => import('@/components/project-product/detail/term/default-term.vue')
  )
};

const tempTermContent = {
  [TERM_TYPES.LICENSE_AGREEMENT]: '',
  [TERM_TYPES.TERMS_OF_SERVICE]: '',
  [TERM_TYPES.CHECKOUT_NOTE]: '',
  [TERM_TYPES.THIRD_PARTY]: '',
  [TERM_TYPES.PROTECTION_AUTOMATIC]: '',
  [TERM_TYPES.PARENTAL_CONSENT]: '',
  [TERM_TYPES.PERSONAL_CONSENT]: ''
};

const termComponent = (term: string) => {
  if (!term) {
    return '';
  }

  return Object.values(TERM_TYPES).find((type: string) => term.includes(type)) ?? '';
};

const formattedLanguages = computed(() => {
  return languages.value.map((lang: Language) => ({
    ...lang,
    value: lang.value.toLowerCase()
  }));
});

const termTypesOptions = computed<FormOption[]>(() => {
  return Object.keys(TERM_TYPES).map((key: string) => {
    const term = TERM_TYPES[key as keyof typeof TERM_TYPES];

    return {
      label: getTermLabel(term),
      value: term
    };
  });
});

const effectiveDateOptions: FormOption[] = [
  {
    value: EFFECTIVE_FROM.IMMEDIATE,
    label: 'studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_date_immediate'
  },
  {
    value: EFFECTIVE_FROM.RESERVATION,
    label: 'studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_date_select_time'
  }
];

const translatedContent = ref<TranslatedContent[]>([]);

const versionId = computed(() => route.query.id);
const projectId = computed(() => route.params.projectId as string);

const locale = computed(() => {
  return selectedGroupInfo.value?.languageCd ?? LanguageCode.Ko;
});

const referenceLanguage = computed(() => {
  if (locale.value === LanguageCode.En) {
    return 'studio.basic_lang_setting.us';
  }

  return `studio.basic_lang_setting.${locale.value.replace('-', '_')}`;
});

const onSettingMultiLanguage = async () => {
  if (termContent.value === '') {
    await showAlert({
      content: t('studio.common.popup_case_lang_setting_input_required', {
        reqValue: t('studio.ai_translation.title9_terms')
      })
    });
    return;
  }

  const sourceLangName = Object.keys(LanguageCode).find(
    (key: string) => LanguageCode[key as keyof typeof LanguageCode] === locale.value
  );

  const translations = await showDialog<TranslatedContent[]>({
    component: shallowRef(MultiLanguageDialog),
    props: {
      page: MULTILANGUAGE_DIALOG_NAME.TERM_FORM,
      content: termContent.value,
      sourceLang: {
        name: sourceLangName,
        value: locale.value
      },
      translatedContent: translatedContent.value.filter(
        (t: TranslatedContent) => t.lang !== locale.value
      ),
      projectId,
      readonly: !isLatestVersion.value || !isProductWritable.value,
      disabled: !isLatestVersion.value
    },
    severity: 'info'
  });

  if (translations) {
    translatedContent.value = translations;
    isContentUpdated.value = false;
  }
};

const formatRequest = (value: TermForm) => {
  const terms = translatedContent.value.map((t: TranslatedContent) => ({
    languageCd: t.lang,
    text: t.content
  }));

  const isDefaultTerm = terms.find(
    (t: { languageCd: string; text: string }) => t.languageCd === locale.value
  );
  if (!isDefaultTerm) {
    terms.push({
      languageCd: locale.value,
      text: termContent.value
    });
  } else {
    isDefaultTerm.text = termContent.value;
  }

  const enforcedTime =
    value.effectiveFrom === EFFECTIVE_FROM.RESERVATION
      ? {
        type: TermEnforceTimeType.SCHEDULED,
        scheduledAt: value.effectiveDate ? value.effectiveDate.getTime() : 0
      }
      : {
        type: TermEnforceTimeType.IMMEDIATELY
      };

  const request: TermsAndConditionRequest = {
    termsName: value.termName,
    defaultLanguageCd: locale.value,
    contents: terms,
    enforcedTime,
    subCategory: value.subCategory
  };

  return request;
};

const displayAlert = async (
  content: string,
  isCancelButtonVisible: boolean = false,
  confirmLabel: string = 'studio.common.popup_case_cf_btn'
) => {
  const res = await showAlert({
    content,
    isCancelButtonVisible,
    cancelVariant: 'outline',
    confirmLabel: t(`${confirmLabel}`)
  });
  return res;
};

const submitData = async (
  request: TermsAndConditionRequest,
  confirmType: TermsConfirmType,
  delay: number = 0
) => {
  request.confirmType = confirmType;
  request.subCategory = values.subCategory;

  if (!product.value) {
    return;
  }

  const res = await updateTermByVersionApi({
    productNo: product.value.gameId,
    request
  });

  // DON'T ASK WHY - https://sgp-internal.slack.com/archives/C07K2R2CKCL/p1733130088227139?thread_ts=1732158121.291179&cid=C07K2R2CKCL
  if (delay > 0) {
    const id = generateRandomNumber();
    startLoading(id);
    await delayTime(delay);
    endLoading(id);
  }

  return res;
};

const checkData = async (request: TermsAndConditionRequest) => {
  const time = 3000;

  if (isEditMode.value) {
    const result = await showDialog({
      component: shallowRef(ReleaseDialog)
    });

    if (!result) {
      return;
    }

    // Process based on return type from CHECK API
    const res = await submitData(request, TermsConfirmType.CHECK, time);

    // SUCCESS
    if (res?.value) {
      await displayAlert(t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_saved_popup'));

      isCheckLeave.value = false;
      redirectTo(PRODUCT_TERMS_PAGE_URL);

      return;
    }

    switch (res?.code) {
      case 2303:
        await displayAlert(
          t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_date_sel_guide')
        );
        break;
      case 2304:
        await displayAlert(
          t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_date_change_x')
        );
        break;
      case 2305:
        {
          const confirm = await displayAlert(
            t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_case_1'),
            true,
            'studio.common.popup_case_apply_btn'
          );

          if (confirm) {
            try {
              await submitData(request, TermsConfirmType.IMMEDIATELY_NEW_VERSION, time);
              await displayAlert(
                t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_saved_popup')
              );
              isCheckLeave.value = false;
              redirectTo(PRODUCT_TERMS_PAGE_URL);
            } catch (error) {}
          }
        }
        break;
      case 2306:
        {
          const confirm = await displayAlert(
            t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_case_2'),
            true,
            'studio.common.popup_case_apply_btn'
          );

          if (confirm) {
            try {
              await submitData(request, TermsConfirmType.IMMEDIATELY_KEEP_VERSION, time);
              await displayAlert(
                t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_saved_popup')
              );
              isCheckLeave.value = false;
              redirectTo(PRODUCT_TERMS_PAGE_URL);
            } catch (error) {}
          }
        }
        break;
      case 2307:
        {
          const confirm = await displayAlert(
            t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_apply_sched_guide'),
            true,
            'studio.common.popup_case_apply_btn'
          );

          if (confirm) {
            try {
              await submitData(request, TermsConfirmType.SCHEDULED_NEW_VERSION, time);
              await displayAlert(
                t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_saved_popup')
              );
              isCheckLeave.value = false;
              redirectTo(PRODUCT_TERMS_PAGE_URL);
            } catch (error) {}
          }
        }
        break;
      default:
        await displayAlert(t('studio.common.def_key.error'));
        break;
    }

    return;
  }

  delete request.enforcedTime;
  delete request.confirmType;
  delete request.termsTypeId;

  if (!product.value) {
    return;
  }

  try {
    await createTermApi({
      productNo: product.value.gameId,
      request
    });
    await displayAlert(t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_saved_popup'));
    isCheckLeave.value = false;
    redirectTo(PRODUCT_TERMS_PAGE_URL);
  } catch (error) {}
};

const isMultiLanguagePopupShown = () => {
  const translated = translatedContent.value.filter(
    (t: TranslatedContent) => t.lang !== locale.value
  );

  return translated.some((t: TranslatedContent) => t.content === '');
};

const checkTermContent = async () => {
  if (!(await checkProductPermission())) {
    await redirectTo(PRODUCT_TERMS_PAGE_URL);
    return;
  }
  await validate();

  showError.value = false;

  if (meta.value.valid) {
    onSubmit();
  }
};

const onSubmit = async () => {
  if (!(await checkProductPermission())) {
    await redirectTo(PRODUCT_TERMS_PAGE_URL);
    return;
  }

  showError.value = false;

  if (wordCount.value > WORD_COUNT_LIMIT) {
    setContentError(
      generateErrorMsg(
        t('studio.common.def_key.exceed', { length: WORD_COUNT_LIMIT }),
        RuleNames.MAX_LENGTH
      )
    );
    showError.value = true;

    return;
  }

  const request = formatRequest(values);

  if (isMultiLanguagePopupShown()) {
    await showAlert({
      content: t('studio.common.popup_case_ai_not_entered')
    });

    onSettingMultiLanguage();
    return;
  }

  // If content is updated but Multi language is not updated
  if (isContentUpdated.value) {
    const res = await showAlert({
      content: t('studio.common.popup_case_ai_modified'),
      cancelClasses: 'stds-button stds-button-primary',
      isCancelButtonVisible: true,
      cancelVariant: 'outline',
      confirmLabel: t('studio.common.popup_case_save_btn')
    });

    if (!res) {
      return;
    }

    checkData(request);

    return;
  }

  checkData(request);
};

// Check if the term type is registered
const onVersionChange = async () => {
  if (!selectedVersion.value) {
    return;
  }

  const versionId = allVersions.value.find(
    (v: TermTypeVersionResponse) => v.version === selectedVersion.value
  );

  if (!versionId) {
    return;
  }

  setTermDetailContent(versionId.versionId);
};

const setReleaseVersionOptions = async () => {
  await getAllVersions();

  allReleasedVersions.value = allVersions.value.map((v: TermTypeVersionResponse) => ({
    label: v.version.toUpperCase(),
    value: v.version
  }));
};

const checkTermDefaultContent = () => {
  const noHtmlContent = removeHtmlTags(termContent.value);
  const noHtmlDefaultContent = removeHtmlTags(
    t('studio.prj_prod_mngmt.prod_home.create_term.eula_content_sample', {
      businessName: companyName.value
    })
  );
  const isDefault =
      noHtmlContent.replaceAll(' ', '') === noHtmlDefaultContent.replaceAll(' ', '');

  return isDefault;
};

const getTempContent = (termTypeId: string) => {
  const inputContent = tempTermContent[termTypeId] ?? '';
  if (inputContent) {
    setValues({
      termContent: inputContent
    });

    termContent.value = inputContent;
  } else {
    if (termTypeId === TERM_TYPES.LICENSE_AGREEMENT) {
      setValues({
        termContent: t('studio.prj_prod_mngmt.prod_home.create_term.eula_content_sample', {
          businessName: companyName.value
        })
      });

      termContent.value = t('studio.prj_prod_mngmt.prod_home.create_term.eula_content_sample', {
        businessName: companyName.value
      });

      return;
    }

    setValues({
      termContent: ''
    });

    termContent.value = '';
  }
};

const handleTermTypeChange = async (termTypeId: string) => {
  if (!product.value) {
    return;
  }

  // If the term type is already registered, show a popup
  const isTermRegistered = await checkTermTypeRegisterApi({
    productNo: product.value.gameId,
    termTypeId
  });
  if (isTermRegistered?.isRegistered === Confirmation.YES) {
    await showAlert<boolean>({
      content: t('studio.prj_prod_mngmt.prod_home.create_term.term_type_duplicate_popup')
    });

    setValues({ subCategory: undefined });

    return;
  }

  if (termTypeId === TERM_TYPES.TERMS_OF_SERVICE) {
    editorConfig.value.placeholderText = t(
      'studio.prj_prod_mngmt.prod_home.create_term.usage_content_place_holder'
    );
  } else {
    editorConfig.value.placeholderText = t(
      'studio.prj_prod_mngmt.prod_home.create_term.term_content_place_holder'
    );
  }

  getTempContent(termTypeId);

  rerenderEditor.value = false;
  await nextTick();
  rerenderEditor.value = true;
};

const handleFullScreen = async () => {
  const { valueHtml, valueRaw } = await showDialog<{ valueRaw: string; valueHtml: string }>({
    component: shallowRef(FullScreen),
    props: {
      content: termContent.value,
      wordCountLimit: WORD_COUNT_LIMIT,
      toolbarButtons: TOOLBAR_BUTTON_TERMS,
      projectId: projectId.value,
      title: t('studio.ai_translation.title9_terms')
    },
    severity: 'info'
  });

  if (!valueHtml || !valueRaw) {
    return;
  }

  termContent.value = valueHtml;
  setValues({ termContent: valueHtml });
};

const getAllVersions = async () => {
  if (!values.subCategory || !product.value) {
    return;
  }

  const result = await fetchTermTypeVersionListApi(product.value.gameId, values.subCategory);
  allVersions.value = result.contents;
};

const setTermDetailContent = async (versionId: string): Promise<TermDetailResponse | undefined> => {
  if (!product.value) {
    return;
  }

  const res = await fetchTermByVersionIdApi(product.value.gameId, versionId);
  if (!res) {
    return;
  }

  const defaultTermContent =
    res.contents.find(
      (t: TermLanguageContent) => t.languageCd.toUpperCase() === locale.value.toUpperCase()
    )?.text ?? '';

  selectedVersion.value = res.version;
  termContent.value = defaultTermContent;

  if (term.value) {
    term.value.version = res.version;
  }

  const currentTime = new Date().getTime();
  if (res.enforcedAt) {
    let effectiveFrom = EFFECTIVE_FROM.IMMEDIATE;
    const effectiveDate = res.enforcedAt ? new Date(res.enforcedAt) : undefined;

    if (currentTime <= res.enforcedAt) {
      effectiveFrom = EFFECTIVE_FROM.RESERVATION;
    }

    setValues({
      effectiveFrom,
      effectiveDate
    });
  }

  setValues({
    termName: res.name,
    lang: res.defaultLangCd,
    termContent: defaultTermContent,
    subCategory: res.subCategory,
    version: res.version
  });

  return res;
};

const getTermDetailContent = async () => {
  const res = await setTermDetailContent(versionId.value as string);

  selectedVersion.value = res?.version;

  if (!versionId.value || !res) {
    return;
  }

  term.value = res;

  translatedContent.value = res.contents.map((t: TermLanguageContent) => ({
    lang: t.languageCd,
    content: t.text
  }));

  formattedLanguages.value.forEach((lang: Language) => {
    if (
      !translatedContent.value.find(
        (term: TranslatedContent) => term.lang.toUpperCase() === lang.value.toUpperCase()
      )
    ) {
      translatedContent.value.push({ lang: lang.value.toUpperCase(), content: '' });
    }
  });

  await setReleaseVersionOptions();
};

const init = async () => {
  if (isEditMode.value) {
    try {
      await getTermDetailContent();
      // Check if the term is the latest version to display view mode
      isLatestVersion.value = term.value?.version === allVersions.value[0]?.version;
    } catch (err) {
      const error = err as ErrorResponse;
      const errorCode = error.statusCode ?? 0;
      // TODO: translate error
      await showAlert({
        severity: 'danger',
        content: errorCode.toString()
      });
    }
  } else {
    if (formattedLanguages.value.length === 0) {
      await fetchLanguages();
    }

    // Route from indie-studio-v2 to here to create term
    const currentType = route.query?.type as string;
    if (currentType === TERM_TYPES.TERMS_OF_SERVICE) {
      setValues({
        subCategory: TERM_TYPES.TERMS_OF_SERVICE
      });

      editorConfig.value.placeholderText = t(
        'studio.prj_prod_mngmt.prod_home.create_term.usage_content_place_holder'
      );
    }

    translatedContent.value = formattedLanguages.value.map((lang: Language) => ({
      lang: lang.value,
      content: ''
    }));
  }
  try {
    const businessInfo = await fetchBusinessInfosApi(selectedGroupId.value, false);
    companyName.value = businessInfo?.approveCompanyInfo?.companyInfo?.companyName ?? '';
  } catch (err) {
    const error = err as ErrorResponse;

    if (error.statusCode === STATUS_CODE.ACCESS_DENIED) {
      companyName.value = `%${t('studio.business_bank_setting.business_info6')}%`;
      return;
    }

    await showAlert({
      content: t(COMMON_ERROR_MESSAGE_KEY)
    });
  }
};

const removeHtmlTags = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const handleModelUpdate = () => {
  isContentUpdated.value = true;
  showError.value = false;
};

init();

watch(() => termContent.value, () => {
  if (values.subCategory) {
    if (values.subCategory === TERM_TYPES.LICENSE_AGREEMENT) {
      const isDefault = checkTermDefaultContent();

      if (!isDefault) {
        setValues({
          termContent: termContent.value
        });
        tempTermContent[values.subCategory] = termContent.value;
      } else {
        tempTermContent[values.subCategory] = '';
      }
    } else {
      setValues({
        termContent: termContent.value
      });
      tempTermContent[values.subCategory] = termContent.value;
    }
  }
});

onBeforeRouteLeave(async () => {
  if (isCheckLeave.value) {
    const result = await showConfirmLeaveDialog();
    return result;
  }
});
</script>
